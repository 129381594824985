const $ = window.jQuery;

export default class ToggleDisplay {
  constructor(element) {
    let $element = $(element);

    let targetSelector = $element.data("target-selector");
    let $targetSelector = $(targetSelector);

    if (!targetSelector || targetSelector == ".or-header__navigation") return;

    $element.on("click", function (e) {
      e.preventDefault();
      console.log($targetSelector.data());

      if ($targetSelector.data("visible") == false) {
        $targetSelector.data("visible", true);
        $targetSelector.attr("data-visible", true);
        $element.attr("data-target-visible", true);
      } else {
        $targetSelector.data("visible", false);
        $targetSelector.attr("data-visible", false);
        $element.attr("data-target-visible", false);
      }
    });
  }
}
