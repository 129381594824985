/*
  Automatically instantiates modules based on data-attrubiutes
  specifying module file-names.
*/

import copyUrl from "./copyUrl.js";
import embeddedVideo from "./embeddedVideo.js";
import eventTrigger from "./eventTrigger.js";
import findNearest from "./findNearest.js";
import hamburgerButton from "./hamburgerButton.js";
import regionManager from "./regionManager.js";
import regionSearch from "./regionSearch.js";
import toggleDisplay from "./toggleDisplay.js";
import toggleHeight from "./toggleHeight.js";
import twoWayBindLink from "./twoWayBindLink.js";

const modules = {
  copyUrl,
  embeddedVideo,
  eventTrigger,
  findNearest,
  hamburgerButton,
  regionManager,
  regionSearch,
  toggleDisplay,
  toggleHeight,
  twoWayBindLink,
};

const moduleElements = document.querySelectorAll("[data-module]");

for (var i = 0; i < moduleElements.length; i++) {
  const el = moduleElements[i];
  const name = el.getAttribute("data-module");
  const Module = modules[name];
  new Module(el);
}
