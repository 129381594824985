const $ = window.jQuery;

export default class ToggleHeight {
  constructor(element) {
    let $element = $(element);

    let targetSelector = $element.data("target-selector");

    if (!targetSelector || targetSelector == ".or-header__navigation") return;

    $element.on("click", function (e) {
      e.preventDefault();

      if ($(targetSelector).css("height") == "0px") {
        $(targetSelector).css(
          "height",
          $(targetSelector).get(0).scrollHeight + "px"
        );
        element.setAttribute("data-expanded", true);
      } else {
        $(targetSelector).css("height", "0px");
        element.setAttribute("data-expanded", false);
      }
    });
  }
}
