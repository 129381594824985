import "./modules";

import "what-input";

import svg4everybody from "svg4everybody";
svg4everybody();

import objectFitImages from "object-fit-images";
objectFitImages(null, { watchMQ: true });

import "lazysizes";
