const $ = window.jQuery;
import regionManager from "./regionManager.js";

export default class RegionSearch {
  constructor(element) {
    this.element = element;
    let input = element.querySelector("[data-input]");
    let self = this;
    //let loader = element.querySelector('[data-loader]');

    let options = {
      language: "sv-SE",
      types: ["(cities)"],
      componentRestrictions: { country: "se" },
    };

    let autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener("place_changed", function () {
      self.loading(true);
      let place = autocomplete.getPlace();

      if (place.length == 0) return;

      let bounds = new google.maps.LatLngBounds();
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      let myPosition = {
        location: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      };
      regionManager.getPlace(myPosition, function (region) {
        self.loading(true);
        if (region) {
          let $regionLink = $('li[data-region-name="' + region + '"] > a');

          if ($regionLink.length) {
            $regionLink[0].click();
          }
        }
      });
    });
  }

  loading(show) {
    if (show) {
      this.element.setAttribute("data-loading", "");
    } else {
      this.element.removeAttribute("data-loading");
    }
  }
}
