const $ = window.jQuery;

import * as clipboard from "clipboard-polyfill";

export default class CopyUrl {
  constructor(element) {
    let $element = $(element);

    let currentUrl = window.location.href;

    let copyLinkBtn = $('[data-id="shareButton"]');

    let shareButtonLabel = $('[data-id="shareButtonLabel"]');

    let shareButtonIcon = $('[data-id="shareButtonIcon"]');

    let shareButtonIconOriginal = shareButtonIcon.attr("href");

    let shareButtonIconSuccess = shareButtonIcon.attr("data-icon-success");

    $(copyLinkBtn).click(function () {
      copyToClipboard(currentUrl);
    });

    function copyToClipboard(text) {
      clipboard.writeText(text);

      shareButtonLabel.text("Länk kopierad!");
      shareButtonIcon.attr("href", shareButtonIconSuccess);
      shareButtonIcon.attr("xlink:href", shareButtonIconSuccess);

      setTimeout(function () {
        shareButtonLabel.text("Kopiera länk");
        shareButtonIcon.attr("href", shareButtonIconOriginal);
        shareButtonIcon.attr("xlink:href", shareButtonIconOriginal);
      }, 3000);
    }
  }
}
