
export default class RegionManager{

    static getRegion(place){
        //Format place name
      place = place.toLowerCase();
      place = place.replace(/å/gi, "a").replace(/ä/gi, "a").replace(/ö/gi, "o").replace(" ", "-");

      let region = RegionManager.placeToRegion(place + '-kommun');
      if(!region){
        region = RegionManager.placeToRegion(place + 's-kommun');
      }

      return region;
    }

    static getPlace(obj, callback){

        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(obj, function(result, status){
            if (status === 'OK') {
              result.forEach(function(res){
                res.types.forEach(function(type){
                  if (type === 'administrative_area_level_2') {
                    let region = RegionManager.getRegion(res.address_components[0].long_name);
                    if(typeof callback == 'function'){
                        callback(region);
                    }
                  }
                });
              });
            }
            else{
              console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    static placeToRegion(place){
        let strLan;
        if (place == "karlshamns-kommun" || place == "karlskrona-kommun" || place == "olofplaceoms-kommun" || place == "ronneby-kommun" || place == "solvesborgs-kommun") {
            strLan = "blekinge"
        } else if (place == "avesta-kommun" || place == "borlange-kommun" || place == "falu-kommun" || place == "gagnefs-kommun" || place == "hedemora-kommun" || place == "leksands-kommun" || place == "ludvika-kommun" || place == "malung-salens-kommun" || place == "mora-kommun" || place == "orsa-kommun" || place == "rattviks-kommun" || place == "smedjebackens-kommun" || place == "saters-kommun" || place == "vansbro-kommun" || place == "alvdalens-kommun") {
            strLan = "dalarna"
        } else if (place == "gotlands-kommun") {
            strLan = "gotland"
        } else if (place == "bollnas-kommun" || place == "gavle-kommun" || place == "hofors-kommun" || place == "hudiksvalls-kommun" || place == "ljusdals-kommun" || place == "nordanstig-kommun" || place == "ockelbo-kommun" || place == "ovanakers-kommun" || place == "sandvikens-kommun" || place == "soderhamns-kommun") {
            strLan = "gavleborg"
        } else if (place == "falkenbergs-kommun" || place == "halmstads-kommun" || place == "hylte-kommun" || place == "kungsbacka-kommun" || place == "laholms-kommun" || place == "varbergs-kommun") {
            strLan = "halland"
        } else if (place == "bergs-kommun" || place == "bracke-kommun" || place == "harjedalens-kommun" || place == "krokoms-kommun" || place == "ragunda-kommun" || place == "placeomsunds-kommun" || place == "are-kommun" || place == "ostersunds-kommun") {
            strLan = "jamtland-lan"
        } else if (place == "aneby-kommun" || place == "eksjo-kommun" || place == "gislaveds-kommun" || place == "gnosjo-kommun" || place == "habo-kommun" || place == "jonkopings-kommun" || place == "mullsjo-kommun" || place == "nassjo-kommun" || place == "savsjo-kommun" || place == "tranas-kommun" || place == "vaggeryds-kommun" || place == "vetlanda-kommun" || place == "varnamo-kommun") {
            strLan = "jonkopings-lan"
        } else if (place == "borgholms-kommun" || place == "emmaboda-kommun" || place == "hultsfreds-kommun" || place == "hogsby-kommun" || place == "kalmar-kommun" || place == "monsteras-kommun" || place == "morbylanga-kommun" || place == "nybo-kommun" || place == "oskarshamns-kommun" || place == "torsas-kommun" || place == "vimmerby-kommun" || place == "vasterviks-kommun") {
            strLan = "kalmar-lan"
        } else if (place == "alvesta-kommun" || place == "lessebo-kommun" || place == "ljungby-kommun" || place == "markaryds-kommun" || place == "tingsryds-kommun" || place == "uppvidinge-kommun" || place == "vaxjo-kommun" || place == "almhults-kommun") {
            strLan = "kronoberg-lan"
        } else if (place == "arjeplogs-kommun" || place == "arvidsjaurs-kommun" || place == "bodens-kommun" || place == "gallivare-kommun" || place == "haparanda-kommun" || place == "jokkmokks-kommun" || place == "kalix-kommun" || place == "kiruna-kommun" || place == "lulea-kommun" || place == "pajala-kommun" || place == "pitea-kommun" || place == "alvsbyns-kommun" || place == "overkalix-kommun" || place == "overtornea-kommun") {
            strLan = "norrbotten"
        } else if (place == "bjuvs-kommun" || place == "bromolla-kommun" || place == "burlovs-kommun" || place == "bastads-kommun" || place == "eslovs-kommun" || place == "helsingborgs-kommun" || place == "hassleholms-kommun" || place == "hoganas-kommun" || place == "horby-kommun" || place == "hoors-kommun" || place == "klippans-kommun" || place == "kristianstads-kommun" || place == "kavlinge-kommun" || place == "landskrona-kommun" || place == "lomma-kommun" || place == "lunds-kommun" || place == "malmo-kommun" || place == "osby-kommun" || place == "perstorps-kommun" || place == "simrishamns-kommun" || place == "sjobo-kommun" || place == "skurups-kommun" || place == "staffanstorps-kommun" || place == "svalovs-kommun" || place == "svedala-kommun" || place == "tomelilla-kommun" || place == "trelleborgs-kommun" || place == "vellinge-kommun" || place == "ystads-kommun" || place == "astorps-kommun" || place == "angelholms-kommun" || place == "orkelljunga-kommun" || place == "oplacea-goinge-kommun") {
            strLan = "skane"
        } else if (place == "botkyrka-kommun" || place == "danderyds-kommun" || place == "ekero-kommun" || place == "haninge-kommun" || place == "huddinge-kommun" || place == "jarfalla-kommun" || place == "lidingo-kommun" || place == "nacka-kommun" || place == "norrtalje-kommun" || place == "nykvarns-kommun" || place == "nynashamns-kommun" || place == "salems-kommun" || place == "sigtuna-kommun" || place == "sollentuna-kommun" || place == "solna-kommun" || place == "stockholms-kommun" || place == "sundbybergs-kommun" || place == "sodertalje-kommun" || place == "tyreso-kommun" || place == "taby-kommun" || place == "upplands-vasby-kommun" || place == "upplands-bro-kommun" || place == "vallentuna-kommun" || place == "vaxholms-kommun" || place == "varmdo-kommun" || place == "osterakers-kommun") {
            strLan = "storstockholm"
        } else if (place == "ales-kommun" || place == "lilla-edets-kommun" || place == "alingsas-kommun" || place == "molndals-kommun" || place == "harrydas-kommun" || place == "partilles-kommun" || place == "kungalvs-kommun" || place == "stenungsunds-kommun" || place == "lerums-kommun" || place == "tjorns-kommun" || place == "ockeros-kommun") {
            strLan = "vast"
        } else if (place == "eskilstuna-kommun" || place == "flens-kommun" || place == "gnesta-kommun" || place == "katrineholms-kommun" || place == "nykopings-kommun" || place == "oxelosunds-kommun" || place == "placeangnas-kommun" || place == "trosa-kommun" || place == "vingakers-kommun") {
            strLan = "sormland"
        } else if (place == "enkopings-kommun" || place == "heby-kommun" || place == "habo-kommun" || place == "knivsta-kommun" || place == "tierps-kommun" || place == "uppsala-kommun" || place == "alvkarleby-kommun" || place == "osthammars-kommun") {
            strLan = "uppsala-lan"
        } else if (place == "arvika-kommun" || place == "eda-kommun" || place == "filipstads-kommun" || place == "forshaga-kommun" || place == "grums-kommun" || place == "hagfors-kommun" || place == "hammaro-kommun" || place == "karlstads-kommun" || place == "kils-kommun" || place == "kristinehamns-kommun" || place == "munkfors-kommun" || place == "storfors-kommun" || place == "sunne-kommun" || place == "saffle-kommun" || place == "torsby-kommun" || place == "arjangs-kommun") {
            strLan = "varmland"
        } else if (place == "bjurholms-kommun" || place == "dorotea-kommun" || place == "lycksele-kommun" || place == "mala-kommun" || place == "nordmalings-kommun" || place == "norsjo-kommun" || place == "robertsfors-kommun" || place == "skelleftea-kommun" || place == "sorsele-kommun" || place == "storumans-kommun" || place == "umea-kommun" || place == "vilhelmina-kommun" || place == "vindelns-kommun" || place == "vannas-kommun" || place == "asele-kommun") {
            strLan = "vasterbotten"
        } else if (place == "harnosands-kommun" || place == "kramfors-kommun" || place == "solleftea-kommun" || place == "sundsvalls-kommun" || place == "timra-kommun" || place == "ange-kommun" || place == "ornskoldsviks-kommun") {
            strLan = "vasternorrland"
        } else if (place == "arboga-kommun" || place == "fagersta-kommun" || place == "hallstahammars-kommun" || place == "kungsors-kommun" || place == "kopings-kommun" || place == "norbergs-kommun" || place == "sala-kommun" || place == "skinnskattebergs-kommun" || place == "surahammars-kommun" || place == "vasteras-kommun") {
            strLan = "vastmanland"
        } else if (place == "lysekils-kommun" || place == "bengtsfors-kommun" || place == "munkedals-kommun" || place == "dals-eds-kommun" || place == "orusts-kommun" || place == "fargelandas-kommun" || place == "sotenas-kommun" || place == "melleruds-kommun" || place == "placeomstads-kommun" || place == "trollhattans-kommun" || place == "tanums-kommun" || place == "vanersborgs-kommun" || place == "uddevallas-kommun" || place == "amals-kommun") {
            strLan = "fyrbodal"
        } else if (place == "boras-kommun" || place == "bollebygds-kommun" || place == "herrljungas-kommun" || place == "marks-kommun" || place == "svenljungas-kommun" || place == "ulricehamns-kommun" || place == "vargardas-kommun") {
            strLan = "sjuharad"
        } else if (place == "gullspangs-kommun" || place == "skara-kommun" || place == "skovde-kommun" || place == "toreboda-kommun" || place == "lidkopings-kommun" || place == "mariestads-kommun" || place == "tibro-kommun" || place == "tidaholms-kommun" || place == "vara-kommun" || place == "essunga-kommun" || place == "falkopings-kommun" || place == "grastorps-kommun" || place == "gotene-kommun" || place == "hjo-kommun" || place == "karlsborgs-kommun") {
            strLan = "skaraborg"
        } else if (place == "goteborgs-kommun") {
            strLan = "goteborg"
        } else if (place == "askersunds-kommun" || place == "degerfors-kommun" || place == "hallsbergs-kommun" || place == "hallefors-kommun" || place == "karlskoga-kommun" || place == "kumla-kommun" || place == "laxa-kommun" || place == "lekebergs-kommun" || place == "lindesbergs-kommun" || place == "ljusnarsbergs-kommun" || place == "nora-kommun" || place == "orebro-kommun") {
            strLan = "orebro-lan"
        } else if (place == "boxholms-kommun" || place == "finnspangs-kommun" || place == "kinda-kommun" || place == "linkopings-kommun" || place == "mjolby-kommun" || place == "motala-kommun" || place == "norrkopings-kommun" || place == "soderkopings-kommun" || place == "vadstena-kommun" || place == "valdemarsviks-kommun" || place == "ydre-kommun" || place == "atvidabergs-kommun" || place == "odeshogs-kommun") {
            strLan = "ostergotland"
        } else {
            return false;
        }
        return strLan;

    }
}