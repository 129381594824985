const $ = window.jQuery;

export default class embeddedVideo {
  constructor(element) {
    let self = this;

    let $element = $(element);
    let type = $element.data("video-type");
    let videoId = $element.data("video-id");
    let videoWidth = $element.data("video-width");
    let video = element.querySelectorAll("[data-video]");

    let loader = element.querySelectorAll("[data-loader]");

    let $video = $(video);
    let $loader = $(loader);

    //Expose to class
    self.$loader = $loader;

    if (type == "youtube") {
      if (typeof window.playerList == "undefined") {
        window.playerList = [];
      }

      let elementId = $video.attr("id");

      window.playerList.push({
        elementId: elementId,
        video: {
          videoId: videoId,
          width: videoWidth,
          suggestedQuality: "hd720",
        },
      });

      this.youtubeLibrary();

      $(document).on("youtubeDone", function () {
        self.onReady();
      });

      $(document).ready(function () {
        window.onYouTubeIframeAPIReady = function () {
          $(document).trigger(["youtubeDone"]);

          window.playerList.forEach(function (item, i) {
            window.playerList[i] = new YT.Player(item.elementId, item.video);
          });
        };
      });
    } else if (type == "facebook") {
      this.facebookLibrary();

      window.fbAsyncInit = function () {
        var my_video_player;
        FB.Event.subscribe("xfbml.ready", function (msg) {
          if (msg.type === "video") {
            my_video_player = msg.instance;
          }
        });
      };

      $(document).ready(function () {
        let i = 0;
        $video.bind("DOMSubtreeModified", function () {
          i++;
          if (i > 4) {
            self.onReady();
          }
        });
      });
    }
  }

  onReady() {
    this.$loader.css("display", "none");
  }

  facebookLibrary() {
    if (window.facebookVideoIsLoaded) return;

    window.facebookVideoIsLoaded = true;

    try {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/sv_SE/sdk.js#xfbml=1&version=v2.6";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    } catch (err) {
      console.log(err);
    }
  }

  youtubeLibrary() {
    if (window.youtubeVideoIsLoaded) return;

    window.youtubeVideoIsLoaded = true;

    let tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";

    let firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}
