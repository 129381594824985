const $ = window.jQuery;
import regionManager from "./regionManager.js";

export default class FindNearest {
  constructor(element) {
    this.element = element;
    let self = this;
    let $element = $(element);

    $element.click(function () {
      self.loading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          let myPosition = {
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          };

          regionManager.getPlace(myPosition, function (region) {
            if (region) {
              let $regionLink = $('li[data-region-name="' + region + '"] > a');

              if ($regionLink.length) {
                $regionLink[0].click();
              }
            }
          });
        });
      } else {
        self.loading(false);
        return "Geolocation is not supported by this browser.";
      }
    });
  }

  loading(show) {
    if (show) {
      this.element.setAttribute("data-loading", "");
    } else {
      this.element.removeAttribute("data-loading");
    }
  }
}
