const $ = window.jQuery;

export default class EventTrigger {
  constructor(element) {
    let $element = $(element);

    let listenEvent = $element.data("listen-event");
    let triggerEvent = $element.data("trigger-event");

    if (!listenEvent || !triggerEvent) return;

    $element.on(listenEvent, function (e) {
      $(document).trigger(triggerEvent);
    });
  }
}
