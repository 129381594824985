
export default class HamburgerButton {
  constructor(element) {
    console.log("Test HamburgerButton");

    let menuId = element.getAttribute('aria-controls');
    if (!menuId) {
      return;
    }

    let menu = document.getElementById(menuId);
    if (!menu) {
      return;
    }

    element.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      let expanded = menu.getAttribute('aria-expanded');
      if(expanded === 'true') {
        menu.setAttribute('aria-expanded', 'false');
        element.removeAttribute('data-expanded');
      } else {
        menu.setAttribute('aria-expanded', 'true');
        element.setAttribute('data-expanded', '');
      }
    });
  }
}
