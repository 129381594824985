const $ = window.jQuery;

export default class TwoWayBindLink {
  constructor(element) {
    let self = this;
    let $element = $(element);

    let $bindElement = this.getFormatedId($element.data("bind-id"));

    if (!$bindElement.length) return;

    $bindElement.hover(
      function () {
        self.addActive($element);
      },
      function () {
        self.removeActive($element);
      }
    );

    $element.hover(
      function () {
        self.addActive($bindElement);
      },
      function () {
        self.removeActive($bindElement);
      }
    );

    $bindElement.click(function (e) {
      $element[0].click();
    });
  }

  addActive($elem) {
    $elem.attr("data-active", "");
  }

  removeActive($elem) {
    $elem.removeAttr("data-active");
  }

  getFormatedId(elemId) {
    if (!elemId) return null;

    elemId = elemId.replace(" ", "_");
    elemId = "#" + elemId;

    return $(elemId);
  }
}
